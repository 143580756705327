<template>
    <div class="hotScripts-container">
        <div class="back-btn">
            <el-button size="medium" @click="backBtn">返回</el-button>
        </div>
        <div class="hotScripts-detail-content">
            <iframe :src="scriptInfo.script_file" v-if="scriptInfo.script_file" frameborder="0" width="100%" height="100%"></iframe>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                scriptId: Number(this.$route.query.id) || '',
                scriptInfo: {
                    script_file: ''
                }
            }
        },
        mounted() {
            this.getScriptDetail();
        },
        methods: {
            getScriptDetail() {
                let params = {
                    id: this.scriptId
                }
                this.$httpStudent.axiosGetBy(this.$api.getScript, params, (res) => {
                    if (res.code === 200) {
                        this.scriptInfo = res.data.data[0]
                    }
                })
            },
            backBtn() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .hotScripts-container {
        height: calc(100vh - 80px);
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        background: #fff;
        margin-bottom: -20px;
        .back-btn {
            padding: 10px;
        }
        .hotScripts-detail-content {
            height: 1%;
            flex: 1;
        }
    }
</style>